/* src/components/Loader.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--pl-color2); /* Optional: You can set the background color to anything you like */
}

.loader-logo {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}