@import url('https://fonts.cdnfonts.com/css/premier-sans');
.bgMenu{
    background: linear-gradient(137.27deg, #05f0ff 19.85%, #7367ff 91.08%, #963cff 113.26%);
}
.subNav {
    line-height: 4.2rem;
    position: relative;
    z-index: 1;
    margin-left: 0;
    margin-right: 0;
}
.subNav ul:not(.moreToggleDropdown) {
    padding-left: 1.5rem;
    height: 4.5rem;
}
.subNav li {
    float: left;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
}
.subNav a:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    background: linear-gradient(137.27deg, #05f0ff 19.85%, #7367ff 91.08%, #963cff 113.26%);
    transition: .2s;
    z-index: -1;
}
a.activeLine:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    width: 100%;
    background: linear-gradient(137.27deg, #05f0ff 19.85%, #7367ff 91.08%, #963cff 113.26%);
    transition: .2s;
    z-index: -1;
}

.subNav a:hover::before {
    height: 5px;
}

.subNav a {
    height: 100%;
    display: flex;
    align-items: center;
    color: #76766f;
    text-decoration: none;
    /* padding: .1rem rem; */
    /* font-size: 1rem; */
    line-height: 1.8rem;
    font-family: PremierSans-Heavy, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 600;
    position: relative;
    transition: all .2s ease-in-out;
}
.subNav span {
    height: 100%;
    display: flex;
    align-items: center;
    color: #76766f;
    line-height: 1.8rem;
    font-family: PremierSans-Heavy, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 600;
    position: relative;
    transition: all .2s ease-in-out;
}
.subNav span:hover {
   background-color: #EBE5EB ;
}

@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .slide-in {
    background-color: #fff;
    animation: slideInFromRight .5s ease-out forwards;
  }
    