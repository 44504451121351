.pl-table-container {
  padding: 20px;
}

.predictions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.view-match-stats-button {
  display: flex;
  margin: 20px 0;
}

.float-right {
  float: right;
}

.chevron {
  font-size: 20px;
  padding-left: 10px;
}
