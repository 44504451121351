.charts-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid var(--pl-color);

    display: -webkit-flex;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
}

.chart-item {
    height: 20em;
    width: 20em;
}

@media (max-width: 600px) {
    .chart-item {
        height: 14em;
        width: 10em !important;
    }
}

/* Media query for smaller mobile devices */
@media (max-width: 400px) {
    .chart-item {
        height: 14em;
        width: 10em !important;
    }
}

.predictions-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .team-chart {
    width: 100%;
    margin-bottom: 20px;
  }