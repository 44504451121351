.toRight {
    transition: translateX(100px);
}

.p-top-2em {
    padding-top: 2em;
}


.display-inline-block {
    display: inline-block;
    width: 100%;
}
