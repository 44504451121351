/* PremierLeagueTable.css */
.pl-table-container {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 600px) {
  .prediction-table-text {
    font-size: 40px;
  }
}