/* src/fonts.css */
@font-face {
    font-family: 'PremierLeague';
    src: url('./assets/fonts/PremierLeagueW01-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PremierLeague';
    src: url('./assets/fonts/PremierLeagueW01-Heavy.woff2') format('woff2');
    font-weight: 900; /* assuming heavy is 900 */
    font-style: normal;
}

@font-face {
    font-family: 'PremierLeague';
    src: url('./assets/fonts/PremierLeagueW01-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
  
  body {
    font-family: 'PremierLeague', sans-serif;
    font-weight: bold;
  }