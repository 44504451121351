:root {
  --pl-color: #37003c; /* Example color */
  --pl-color2: #00ff85; /* Example color */
  --pl-color3: #e90052; /* Example color */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 11.2rem;
  top: 40px;
  position: inherit;
}

@media (max-width: 600px) {
  .logo {
    height: 5rem;
    top: 0;
    position: inherit;
  }
}

.header {
  background-color: var(--pl-color) !important;
}

.header h1 {
  font-size: 1.5em;
}

.header div:first-child {
  height: 100px;
}

.ag-chart-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.position-probabilities-toolbar div:first-child {
  width: 100%;
}

.position-probabilities-toolbar {
  padding: 0 !important;
}

.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* body {
  background-color: var(--pl-color2);
} */

@media (min-width: 1800px) {
  .vertical-height-50 {
    line-height: 50px;
  }
}

.margin-left-right-auto {
  margin-left: auto;
  margin-right: auto;
}

.bold {
  font-weight: bold;
}

.splash-image {
  width: 100%;
}

.image-desktop {
  content: url("./assets/desktopSplash.jpeg");
}

/* For Mobile */
@media only screen and (max-width: 600px) {
  .image-mobile {
    content: url("./assets/mobileSplash.jpeg");
  }
}

.home-text-container-one {
  position: absolute;
  top: 200px;
  left: 50px;
  color: white;
  width: 30% !important;

  p {
    font-size: 5em;
    font-family: "PremierLeague, sans-serif";
    font-weight: bold;
  }
}
/* 
@media only screen and (min-width: 600px) {
  .image-desktop {
    content: url("./assets/desktopSplash.jpeg");
  }

  .home-text-container-two {
    top: 200px;
    width: 30% !important;
  }
} */

/* For Mobile */
@media only screen and (max-width: 768px) {
  .home-text-container-one {
    left: 0px;
    width: 100% !important;

    p {
      font-size: 2em;
    }
  }

  .home-text-container-one p {
    font-size: 2em;
  }

  /* .home-text-container-two {
    width: 100% !important;
    bottom: 0;
    text-align: center;

    p {
      font-size: 1em !important;
    }
  } */
}

.home-container p {
  font-family: 'PremierLeague', sans-serif;
  font-weight: bold;
}

.home-text-container-main p {
  font-size: 1.5em;
}

.home-text-container {
  max-width: 960px; /* Adjust this value based on your preference or design */
  margin: 0 auto; /* Center the container horizontally */
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
}

.grid-item {
  display: flex;
  flex: 1;
  max-width: 25%; /* 4 boxes in a row */
  box-sizing: border-box;
  padding: 16px; /* Adjust the padding based on your design */
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px; /* Adjust the padding based on your design */
  text-align: center;
  background-color: #ffffff; /* Use the appropriate background color */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* Adjust the shadow based on your design */
  border-radius: 4px; /* Adjust the border radius based on your design */
}

.icon {
  font-size: 2rem; /* Adjust the font size based on your design */
  margin-bottom: 8px; /* Adjust the margin based on your design */
}

/* Media queries for responsiveness */
@media (max-width: 960px) {
  .grid-item {
      max-width: 50%; /* 2 boxes in a row on tablets */
  }
}

@media (max-width: 600px) {
  .grid-item {
      max-width: 100%; /* 1 box in a row on mobile */
  }
}


.min-width-62 {
  min-width: 62px;
}