.tableHerp:after {
  content: "";
  position: absolute;
  width: 100%;
  /* height: 250px; */
  left: 0;
  top: 0;
  background-image: url(/src/assets/teamLogos/tableHero.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .tableHerp:after {
      height: 250px;
  }
}
@media screen and (max-width: 767px) {
  .tableHerp:after {
      height: 200px;
  }
}

.tableHead {
  background-color: #fbfafa;
  border-bottom: .1rem solid #ebe5eb;
  color: #87668a;
  font-family: PremierSans-Heavy, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.8rem;
}

.teamName {
  font-family: PremierSans-Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 3.2rem;
  letter-spacing: -.04rem;
  text-transform: capitalize;
  color: #37003c;
  margin-left: 1rem;
}
/* Hover for Teams Versus */
.verBg:hover{
  transition: all .1s ease-in-out;
  background: linear-gradient(98.5deg, #05f0ff -46.16%, #7367ff 42.64%, #963cff 70.3%);
}
/* the Dot after positiono */

table .league-table__movement.none {
  border-radius: 100%;
  width: .3rem;
  height: .3rem;
  background: #87668a;
  top: -.2rem;
}
.league-table__tooltip-container.tooltip-left {
  left: -1.15rem;
}
.tooltipContainer .tooltip-content {
  background: #fff;
  border-radius: .3rem;
  border: .1rem solid #ebe5eb;
  position: relative;
  display: block;
  padding: .8rem;
}
table .league-table__movement {
  position: relative;
  display: inline-block;
  margin-left: 1.1rem;
  transform: none;
}

.tooltipContainer.tooltip-left {
  left: -1.15rem;
  transform: translateX(0);
}
.league-table__tooltip-container {
  bottom: calc(-50% + .45rem);
}
.tooltipContainer {
  position: absolute;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  padding: 0 0 1rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  overflow: visible;
  color: #37003c;
  opacity: 0;
  transition: .2s;
}
.tooltipContainer, .visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.league-table td {
  text-align: center;
}
.league-table__pos {
  display: table-cell;
  padding-left: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #37003c;
  text-align: left !important;
}
.button-tooltip {
  position: relative;
  display: block;
  z-index: 1;
  cursor: pointer;
}
.league-table__tooltip-content {
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: -.04rem;
  color: #37003c;
}